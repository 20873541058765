<template>
<div class="my-qrcode">
    <top-bar :title="appName" :left="true"/>
  <img class="qrcode_bgc" src="@/assets/img/communityCode.png" alt="">
  <p>我的二维码</p>
  <!-- <qr-code class="qrcode"  text="Hello world!" :colorDark="config.BYTE_POS" :size="250"></qr-code> -->
  <div id="qrcode" class="qrcode" ref="qrcode"></div>
</div>
</template>

<script>
// import qrCode from 'vue-qr'
import QRCode from 'qrcodejs2'
import topBar from '@/components/topBar/topBar'
import Vue from "vue";
export default {
  components:{
    topBar
  },
    data () {
    return {
       config: {
　　　　　value: 'www.baidu.com',　　//显示的值、跳转的地址
　　　　　BYTE_POS: 'black'
        },
      appName: ''
    }
  },
  methods :{
    creatQrCode() {
      this.$refs.qrcode.innerHTML = ''; //清除二维码方法一
      let text = 'xxxx';
      var qrcode = new QRCode(this.$refs.qrcode, {
        text: text, //页面地址 ,如果页面需要参数传递请注意哈希模式#
        width: 250,
        height: 250,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      })
    }
  },
  mounted () {
    if (Vue.prototype.$globalData.appName) {
      this.appName = Vue.prototype.$globalData.appName
    }
    this.creatQrCode()
  }
}
</script>

<style lang="scss" scoped>
  .my-qrcode {
    height: 100vh;
    position: relative;
    margin-top: 100px;
    .qrcode_bgc {
      width: 750px;
      height: 750px;
    }
    p {
      width: 200px;
      text-align: center;
      position: absolute;
      top: 50px;
      left: 50%;
      margin-left: -100px;
      font-size: 36px;
    }
    .qrcode {
      position: absolute;
      top: 200px;
      left: 50%;
      margin-left: -250px;
    }
  }
</style>
